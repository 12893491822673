import { useSingle } from "@opencraft/providence-redux/hooks";
import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { WORKFLOW_API } from "../constants/api-urls";
import { ChecklistList } from "../types/Task";
import { USER_CHECKLISTS } from "../constants/urls";
import { useTranslation } from "react-i18next";

type MyChecklistPageRouteParams = {
    runId: string;
};

const MyChecklistPage = () => {
    const { t } = useTranslation();
    const { runId } = useParams<MyChecklistPageRouteParams>();
    const [checklistId, setChecklistId] = useState(null);
    const { auth } = useAuth();
    const username = auth?.username!;
    const checklistUrl = `${WORKFLOW_API}/user/${username}/checklist/?run_id=${runId}`;
    const controller = useSingle<ChecklistList>("checklist-list", {
        endpoint: `${checklistUrl}`,
    });
    useEffect(() => {
        controller.get().then((resp) => {
            setChecklistId(resp.results[0].id); // there should always only be one user checklist per run
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[runId]);
    if (checklistId) {
        const redirectUrl = `${USER_CHECKLISTS}/${checklistId}`;
        return (
            <Navigate to={redirectUrl} replace />
        );
    }
    return <div>{t("pageStatuses.loading")}</div>;
};

export default MyChecklistPage;
