import { User } from "./User";

export enum ChecklistArchiveAction {
  ARCHIVE = "ARCHIVE",
  UNARCHIVE = "UNARCHIVE",
}

export enum ChecklistStatus {
  UP_COMING = "UP_COMING",
  TO_DO = "TO_DO",
  IN_PROGRESS = "IN_PROGRESS",
  PAST_DUE = "PAST_DUE",
  COMPLETED = "COMPLETED",
}

export enum ChecklistListName {
  TO_DO = "TO_DO",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
  ALL = "ALL",
}

export declare interface TaskBase {
  id: string;
  label: string;
  body: string;
  interface_type: string;
  customization_args: any;
}

export declare interface Task extends TaskBase {
  completed: boolean;
  completed_on: Date;
  required: boolean;
  response: any;
  parent: string;
}

export declare interface Recurrence {
  recurring_schedule_display: string;
  team: string;
  checklist_definition: string;
}

export declare interface Run {
  id: string;
  start_date: string;
  end_date: string;
  due_date: string;
  team_name: string;
  completed_checklist_count: number;
  total_checklist_count: number;
  assignees: User[];
  recurrence: Recurrence;
}

export declare interface TaskList {
  id: string;
  name: string;
  body: string;
  tasks: Array<Task>;
  assignee: User;
  definition: string;
  created_on: Date;
  completed: boolean;
  force_complete: boolean;
  completed_on: Date;
  run?: Run;
}

export declare interface TaskListReview {
  id: string;
  name: string;
  body: string;
  assignee: User;
  created_on: string;
  completed: boolean;
  completed_on: Date;
  run?: Run;
  completed_task_count: number;
  total_task_count: number;
  status: ChecklistStatus;
  is_archived: boolean;
}

export declare interface Result {
  id: string;
}

export declare interface ChecklistList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<Result>;
}
