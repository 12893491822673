import { Dropdown } from 'react-bootstrap';
import { ReactComponent as ThreeDotMenuIcon } from "../assets/icons/three-dot-menu.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share-list.svg";
import useNotification from '../hooks/useNotification';
import { useTranslation } from "react-i18next";
import useAuth from '../hooks/useAuth';

export const ReminderOptionsMenu = ({ incompleteAssignees, checklistDefName, runID }) => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const display_name = auth?.displayName || auth?.username;
    const { setNotification } = useNotification();
    const handleEmailClick = () => {
        const emailList = incompleteAssignees.map(assignee => assignee.email).join(',');
        const link = `${window.location.origin}/${runID}/mychecklist`;
        const subject = encodeURIComponent(t("reports.overview.reminderOptions.emailSubjectText", {
            checklist_def_name: checklistDefName,
        }));
        const bodyTemplate = t("reports.overview.reminderOptions.emailBodyText", {
            display_name: display_name,
            link: 'LINK_PLACEHOLDER'
        });
        const body = encodeURIComponent(bodyTemplate.replace('LINK_PLACEHOLDER', link));
        const mailtoLink = `mailto:${emailList}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    };
    const handleCopyClick = () => {
        const usernameList = incompleteAssignees.map(assignee =>
            `@${assignee.im_handle || assignee.username}`
        ).join(' ');
        navigator.clipboard.writeText(usernameList)
          .then(() => {
            // alert('Copied to clipboard'); // You can replace this with your preferred method to display the message
            setNotification({
                message: t("checklists.notification.copiedToClipboard"),
                variant: "success",
            });
          })
          .catch(() => {
            alert('Failed to copy'); // Error handling
          });
    };
    
    return (
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="px-2 py-0 circle-active ms-auto interaction">
                <ThreeDotMenuIcon className="base-icon" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <p>{t("reports.overview.reminderOptions.sendRemindersText")}</p>
                <Dropdown.Item className="text-reset" onClick={handleEmailClick}>
                    <EmailIcon className="base-icon" />{t("reports.overview.reminderOptions.sendViaEmail")}
                </Dropdown.Item>
                <Dropdown.Item className="text-reset" onClick={handleCopyClick}>
                    <ShareIcon className="base-icon" />{t("reports.overview.reminderOptions.copyMentions")}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ReminderOptionsMenu;
